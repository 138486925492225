import React, { useEffect, useRef, useState } from "react";
import "./appBlog.scss";
import UpcommingEvent from "../../KFLiveSeries/RegistrationSection/Card";
import { CONVEX_API_URL } from "../../../../config/config";
import Card from "./Card";
import { TableOfContents } from "./TableOfContents";
import { Share } from "./Share";

const SideBlog = (props) => {
  const { link } = props;
  const [showToc, setShowToc] = useState(false);
  const [showRelatedContent, setshowRelatedContent] = useState(false);
  const [showKsLiveCard, setshowKsLiveCard] = useState(false);

  var secondFold = null;
  
  try {
    secondFold = (props.toc[0]?.url).split("#")[1];
  }catch {

  } 

  var thirdFold = null;
  
  try{
    thirdFold = (props.toc[1]?.url).split("#")[1];
  }catch{
    
  }
  

  const [activeEvents, setActiveEvents] = useState([]);

  useEffect(() => {
    fetchActiveEvents();
  }, []);

  const fetchActiveEvents = async () => {
    try {
      const url = `${CONVEX_API_URL}/program/null/campaign/null/events/latest-events`;

      const res = await fetch(url);
      const data = await res.json();

      let data1 = [];
      
      if (data && data.length){
        data1 = [data[0]];
      }
      

      if (res.ok && res.status === 200) setActiveEvents(data1);
    } catch (error) {
      console.log(error);
    }
  };

  const observer = useRef();

  useEffect(() => {
    const topMargin = "-180px";

    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const intersecting = entry.isIntersecting;

          if (intersecting) {
            if (entry.target.id === "main-blog-page-header") {
              setShowToc(false);
              setshowRelatedContent(false);
              setshowKsLiveCard(false);
            } else if (entry.target.id === secondFold) {
              setShowToc(true);
              setshowRelatedContent(true);
              setshowKsLiveCard(false);
            } else if (entry.target.id === thirdFold) {
              //setShowToc(true)
              setshowRelatedContent(false);
              setshowKsLiveCard(true);
            }
          }
        });
      },
      {
        rootMargin: topMargin,
      }
    );

    observer.current.observe(document.getElementById("main-blog-page-header"));
    
    if (secondFold){
      observer.current.observe(document.getElementById(secondFold));
    }

    if(thirdFold){
      observer.current.observe(document.getElementById(thirdFold));
    }

    // Clean-up
    return () => observer.current.disconnect();
  }, []);

  return (
    <div className="blog-layout-container">
      {/* <Header /> */}

      <div className="blog-layout-wrapper container-max">
        {!props.hideSidebar && (
          <div className="sidebar2">
            {showToc && (
              <div className="cover mobile-none" id="sidebarHeader">
                <TableOfContents toc={props.toc} />
                <Share link={link} />
              </div>
            )}
          </div>
        )}
        <div
          className={
            "blog-content " + (props.hideSidebar ? "blog-content-center" : "")
          }
        >
          {props.children}
        </div>
        <div className="sidebar">
          {showRelatedContent && (
            <div className="cover" id="sidebar">
              <div className="header">Related courses</div>

              <Card
                title="Marketing Launchpad"
                about="This is 16 week online full-time program for which we select, train and invest in high potential graduates to help them start a fantastic career in marketing. The best part? You pay ONLY when you land a job with a minimum CTC of INR 4.5 lakhs."
                trademark="1800+ students have secured a marketing job without an MBA."
                link={`https://www.kraftshala.com/marketing-launchpad/digital-marketing-course/?utm_medium=blog&utm_source=side_bar&utm_campaign=seo&utm_content=mlp_blog`}
                list={[
                  "16 weeks, online, full-time program",
                  " Get hired in performance marketing, content, social media, and more",
                  "Highest CTC: ₹9.2 LPA",
                  "World’s largest agencies hire from us again and again",
                  "94% placement rate",
                ]}
              />
            </div>
          )}
          {/* <Sidebar3 /> */}
          {showKsLiveCard && (
            <div className="sideBarUpcommingEvent mobile-none">
              <div className="upcoming-tag">Upcoming</div>
              <UpcommingEvent activeEvents={activeEvents} />
            </div>
          )}
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  );
};

export default SideBlog;
