import React from 'react';
import yellowstroke from "../../../images/mlp/yellow-underline.webp";

const Card = (props) => {
  return (
    <div className="sidebar-card">
      <div className="sidebar-title">
        {props.title}
        <span className="text-strokes">
          <img src={yellowstroke} alt="strokes" />
        </span>
      </div>
      <div className="sidebar-trademark">{props.trademark}</div>
      <div className="sidebar-about">
        {/* {props.about} */}
        <ul className="feature-list">
          {props.list.map((el) => {
            return <li>{el}</li>;
          })}
        </ul>
      </div>
      <div className="sidebar-cta">
        <a
          href={props.link}
          className="becomeMarketer"
          target="_blank"
          rel="noreferrer"
        >
            Become A Marketer Today
        </a>
      </div>
    </div>
  );
};

export default Card;